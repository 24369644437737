<template>
	<Dialog :dialog.sync="exportDialog">
		<template v-slot:title> Export {{ title }} </template>
		<template v-slot:body>
			<v-form
				ref="exportForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="exportFilter"
			>
				<v-row>
					<v-col md="12">
						<p class="btx-p m-0">You can export your data from {{ $APPNAME }} in CSV or XLSX format.</p>
					</v-col>
					<!-- <v-col md="12">
					<v-radio-group hide-details id="export-type" class="mt-0 pt-0" v-model="export_type" column>
						<v-radio :label="`All ${title}`" value="all"></v-radio>
						<v-radio label="Specific Period" value="specific_period"></v-radio>
					</v-radio-group>
				</v-col> -->
					<!-- <template v-if="export_type == 'specific_period'">
					<v-col md="6">
						<label for="export-from-date" class="btx-label required">From</label>
						<DatePicker placeholder="From" id="export-from-date" v-model="export_from_date"></DatePicker>
					</v-col>
					<v-col md="6">
						<label for="export-to-date" class="btx-label required">To</label>
						<DatePicker
							placeholder="To"
							id="export-to-date"
							v-model="export_to_date"
							:minDate.sync="export_from_date"
						></DatePicker>
					</v-col>
				</template> -->
					<v-col md="12">
						<label for="export-as" class="btx-label required">Export As</label>
						<v-radio-group hide-details id="export-as" class="mt-2 pt-0" v-model="export_as" column>
							<v-radio label="XLSX (Microsoft Excel)" value="xlsx"></v-radio>
							<v-radio label="CSV (Comma Separated Value)" value="csv"></v-radio>
						</v-radio-group>
					</v-col>
					<v-col md="8" v-if="false">
						<label for="export-password" class="btx-label">Password to protect the file</label>
						<TextInput
							hide-details
							id="export-password"
							placeholder="Enter password"
							v-model="export_password"
						></TextInput>
					</v-col>
					<v-col md="12" v-if="false">
						<p class="btx-p m-0">
							<b>NOTE:</b> You can export only the first 10,000 rows. If you have more rows, please
							initiate a backup for the data in your {{ $APPNAME }} organization, and download it.
							<a href="">Backup Your Data</a>
						</p>
					</v-col>
					<v-col md="12">
						<label for="export-as" class="btx-label required">Export Columns</label>
						<Draggable tag="ul" v-model="colDefs" class="draggable-group" handle=".draggable-drag-icon">
							<template v-if="colDefs.length">
								<template v-for="(col, index) in colDefs">
									<li
										class="draggable-group-item"
										v-if="checkSelected(col) && col.key != 'id' && col.key != 'action' && col.key != 'image'"
										:key="index"
									>
										<v-checkbox
											dense
											v-model="col.visible"
											v-on:change="updateSelected(col)"
											:label="col.name"
											color="cyan"
											hide-details
											class="mt-0 mb-0 mt-2"
										></v-checkbox>
									</li>
								</template>
							</template>
						</Draggable>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="exportFilter">
				{{ exportButton }}
			</v-btn>
			<v-btn depressed tile v-on:click="closeModel"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
/* import DatePicker from "@/view/components/DatePicker.vue"; */
import TextInput from "@/view/components/TextInput.vue";
import Draggable from "vuedraggable";
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { toSafeInteger, isArray, map, filter, cloneDeep } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";

export default {
	name: "export-dialog",
	data() {
		return {
			export_as: "xlsx",
			export_type: "all",
			export_password: null,
			export_from_date: null,
			export_valid: false,
			export_to_date: null,
			colShow: [],
			colDefs: [],
		};
	},
	props: {
		exportDialog: {
			type: Boolean,
			default: false,
		},
		current: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		cols: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	watch: {
		cols: {
			deep: true,
			handler(param) {
				if (param.length > 0) {
					this.colDefs = cloneDeep(param);
					this.colShow = map(cloneDeep(param), (row) => row.id);
				}
			},
		},
	},
	methods: {
		exportFilter() {
			const _this = this;

			const db_notes = filter(this.colDefs, function (row) {
				return row.visible == true;
			});

			this.colShow = map(db_notes, (row) => {
				return row.id;
			});
			const formErrors = _this.validateForm(_this.$refs.exportForm);
			if (formErrors.length) {
				console.log(this.errors);
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.exportForm.validate()) {
				return false;
			}
			if (this.colShow.length == 0) {
				_this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Select At least One Columns" }])
				);
				return false;
			}
			let filter_data = {
				extensions: _this.export_as,
				column: _this.colShow,
			};
			_this.exportData(filter_data);

			this.closeModel();
		},
		exportData(data) {
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + this.endpoint;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			const { query } = this.$route;
			if (data.extensions) {
				downloadURL.searchParams.append("extensions", data.extensions);
			}
			if (data.column) {
				downloadURL.searchParams.append("column", data.column);
			}
			if (query) {
				map(query, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			downloadURL.searchParams.append("token", token);
			window.open(downloadURL, "_blank");
		},
		selectAll() {
			if (this.checkbox) {
				for (let i = 0; i < this.colDefs.length; i++) {
					this.colShow.push(this.colDefs[i].field);
				}
			} else {
				this.colShow = [];
			}
		},
		updateSelected() {
			if (this.colShow && isArray(this.colShow)) {
				const colDefs = toSafeInteger(this.colShow.length);
				const CheckbosLength = toSafeInteger(this.colDefs.length);
				if (CheckbosLength == colDefs) {
					this.checkbox = true;
				} else {
					this.checkbox = false;
				}
			}
		},
		checkSelected(cols) {
			if (this.show_selected) {
				return cols.status;
			}
			return true;
		},
		closeModel() {
			this.colDefs = cloneDeep(this.cols);
			this.colShow = map(this.cols, (row) => row.id);
			(this.export_as = "xlsx"), this.$emit("close", true);
		},
	},
	components: {
		/* DatePicker, */
		Draggable,
		TextInput,
		Dialog,
	},
	computed: {
		exportButton() {
			return this.current ? "Export Current View" : "Export";
		},
		...mapGetters([/* "listingSearch",  */ "errors"]),
	},
};
</script>
